import React, {
  useEffect,
  useRef,
  useState
} from 'react';

import { Checkbox } from '@fluentui/react';
import {
  ISearchBoxStyles,
  SearchBox
} from '@fluentui/react/lib/SearchBox';
import { throttle } from 'lodash';
import intl from 'react-intl-universal';


import { EvidenceSearchControlProps } from './types';

import { LocIds } from '../../common/Globalization/IntlEnum';


const searchBoxStyles: ISearchBoxStyles = {
  root: {
    width: 240,
    marginLeft: 15,
  },
};

const SEARCH_DEBOUNCE_WAIT = 200;


export const EvidenceSearchControl: React.FC<EvidenceSearchControlProps> = (
  props: EvidenceSearchControlProps,
) => {
  const { onSearch } = props;
  const [isExclude, setIsExclude] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const throttledOnSearch = useRef<(value: string) => void>(
    throttle(onSearch, SEARCH_DEBOUNCE_WAIT, {
      trailing: true
    }),
  );

  const formatQuery = (query: string, isExclude: boolean): string =>
    query.trim() ? (isExclude ? `!${query}` : query) : '';

  useEffect(() => {
    const formattedQuery = formatQuery(searchQuery, isExclude);
    throttledOnSearch.current(formattedQuery);
  }, [isExclude, searchQuery]);

  return (
    <>
      <Checkbox
        // eslint-disable-next-line i18next/no-literal-string
        boxSide='end'
        checked={isExclude}
        label={intl.get(
          LocIds.Action.Exclude,
        )}
        onChange={(_, checked) => setIsExclude(checked ?? false)}
      />
      <SearchBox
        className='searchBox'
        placeholder={isExclude ? intl.get(LocIds.Placeholder.TypeToExclude) : intl.get(LocIds.Placeholder.TypeToFilter)}
        styles={searchBoxStyles}
        underlined={false}
        onChange={(_, newValue) => setSearchQuery(newValue ?? '')}
      />
    </>
  );
};
